export default{
    namespaced:false,
    state(){
        return{
            isAdmin:false,
            password:"adminukas"
        }
    },
    mutations:{
        SET_ISADMIN(state, condition){
            state.isAdmin = condition
          }
    },
    actions:{
        loginOut({commit},isAdmin){
            commit('SET_ISADMIN', isAdmin);
        }
    },
    getters: {
        isAdmin(state){
            return state.isAdmin
          },
          password(state){
            return state.password
          }
    }
}