
<template>
  <div
    class="modal fade"
    id="adminLoginModal"
    tabindex="-1"
    aria-labelledby="adminLoginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            v-if="!isAdmin"
            class="modal-title fs-5"
            id="adminLoginModalLabel"
          >
            Login to admin mode
          </h1>
          <h1 v-else class="modal-title fs-5" id="adminLoginModalLabel">
            Logout from admin mode
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div v-if="isAdmin" class="mb-3">At your disposal, Captain</div>
            <div v-else class="mb-3">
              <form>
                <label for="username" class="form-label" hidden>Password</label>
                <input type="text" id="username" hidden autocomplete="current-username">
                <label for="passwordInput" class="form-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': failedPassword }"
                  id="passwordInput"
                  v-model="passwordInput"
                  placeholder="Enter your password"
                  autocomplete="current-password"
                />
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            :class="isAdmin ? 'btn btn-danger' : 'btn btn-primary'"
            @click="loginOut"
          >
            {{ isAdmin ? "Logout" : "Login" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/eventBus";
import bootstrapServices from "@/components/Planner/Services/botstrapServices";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      passwordInput: "",
      failedPassword: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "password"]),
  },
  methods: {
    ...mapActions({
      doLoginout: "loginOut",
    }),
    openLoginModal() {
      bootstrapServices.methods.openModal("adminLoginModal");
    },
    loginOut() {
      //if loged in - logout
      if (this.isAdmin) {
        this.doLoginout(!this.isAdmin);
        this.password = "";
        return;
      }

      if (this.password === this.passwordInput) {
        this.failedPassword = false;
        this.doLoginout(!this.isAdmin);
      } else {
        this.failedPassword = true;
        console.error("Incorect password");
      }
    },
  },
  mounted() {
    EventBus.on("adminLogin", () => {
      this.openLoginModal();
    });
  },
};
</script>
<style>
.is-invalid {
  border-color: #dc3545;
}
</style>