<template>
  <div>
    <div
      class="modal fade modal-lg"
      id="presetsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Preset loader</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <!-- modal body -->
          <div class="modal-body">
            <!-- vertical devider -->
            <div class="row">
              <div class="col-md-6">
                <h3>{{ selected.title || " " }}</h3>
                <img :src="imageUrl" alt="" class="preset-preview" />
                <strong>{{ selected.comments || " " }}</strong>
              </div>
              <div class="col-md-6">
                <!-- acordion -->
                <div class="accordion accordion-flush" id="accordionExample">
                  <div
                    class="accordion-item"
                    v-for="(categoryItem, index) in presets"
                    :key="index"
                  >
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        :data-bs-toggle="'collapse'"
                        :data-bs-target="'#collapse' + index"
                        aria-expanded="false"
                        :aria-controls="'collapse' + index"
                      >
                        {{ categoryItem.category }}
                      </button>
                    </h2>
                    <div
                      :id="'collapse' + index"
                      class="accordion-collapse collapse"
                      :data-bs-parent="'#accordionExample'"
                    >
                      <div class="accordion-body">
                        <div
                          class="preset"
                          v-for="item in categoryItem.list"
                          :key="item.id"
                          @click="previewPreset(item)"
                        >
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              v-if="isAdmin"
              :disabled="!isSelected"
              @click="RemoveSelectedPreset()"
            >
              Remove preset
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :disabled="!isSelected"
              @click="loadPreset()"
            >
              Load preset
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { EventBus } from "@/eventBus";
import bootstrapServices from "@/components/Planner/Services/botstrapServices";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      defaultImage: require("@/assets/no-image-available-png-3.png"),
      selected: {},
      imageUrl: "",
      isSelected: false,
    };
  },
  created() {
    this.fetchPresets();
  },
  computed: {
    ...mapGetters(["presets", "isAdmin"]),
  },
  methods: {
    ...mapActions(["fetchPresets", "removePresetById"]),
    async RemoveSelectedPreset() {
      await this.removePresetById(this.selected.id);
    },
    loadPreset() {
      EventBus.emit(
        "load:preset",
        this.selected.filename.endsWith(".json")
          ? this.selected.filename
          : this.selected.filename + ".json"
      );
    },
    async fetchImage() {
      if (!this.selected.imageFilename) {
        // If imageFilename is not set, use default image
        this.imageUrl = require("@/assets/no-image-available-png-3.png");
        return;
      }
      const imageUrl =
        process.env.VUE_APP_TOOLBOX_BLOB_URL +
        (this.selected.imageFilename.endsWith(".png")
          ? this.selected.imageFilename
          : this.selected.imageFilename + ".png");

      try {
        // Send a HEAD request to check if the image exists
        const response = await fetch(imageUrl, { method: "HEAD" });
        // If image exists, set imageUrl; otherwise, set default
        this.imageUrl = response.ok
          ? imageUrl
          : require("@/assets/no-image-available-png-3.png");
      } catch (error) {
        // In case of any error, log it and use the default image
        console.error("Error checking image:", error);
        this.imageUrl = require("@/assets/no-image-available-png-3.png");
      }
    },
    async showPresetsModal() {
      bootstrapServices.methods.openModal("presetsModal");
    },
    async loadAllPresets() {
      var url = process.env.VUE_APP_PRESETS_URL;
      const response = await fetch(url);
      this.presets = await response.json();
    },
    async previewPreset(item) {
      this.isSelected = true;
      this.selected = item;
      await this.fetchImage();
    },
  },
  async mounted() {
    EventBus.on("newLayoutFromPreset", this.showPresetsModal);
    this.imageUrl = this.defaultImage;
  },
  beforeUnmount() {
    EventBus.off("newLayoutFromPreset", this.showPresetsModal);
  },
};
</script>
<style scoped>
.preset {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  cursor: pointer;
}
.preset:hover {
  background-color: lightgray;
}
.preset-preview {
  width: 300px;
}
</style>