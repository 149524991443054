
const state = {
  presets: [],
};

const mutations = {
  SET_PRESETS(state, presets) {
    if(presets){
      state.presets = presets;
    }else{
      state.presets = {}
    }
    
  },
  REMOVE_PRESET(state, id) {
    for (let category of state.presets) {
      // Find the index of the preset with the matching ID in the current category's list
      const presetIndex = category.list.findIndex((preset) => preset.id === id);
  
      if (presetIndex !== -1) {
        // Remove the preset from the list
        category.list.splice(presetIndex, 1);
        break; // Exit the loop once the item is found and removed
      }
    }
  },
  ADD_PRESET(state, preset){
    state.presets.push(preset)
  }
  
};

const actions = {
  async fetchPresets({ commit }) {
    try {
      var url = process.env.VUE_APP_PRESETS_URL;
      const response = await fetch(url);
      var presets = await response.json();
      commit('SET_PRESETS', presets);
    } catch (error) {
      console.error('Failed to fetch presets:', error);
    }
  },
  async removePresetById({ commit }, id) {
    try {
      // Parameters you want to send
      const params = {
        id: id
      };

      // Construct the query string
      const queryString = new URLSearchParams(params).toString();

      // Append query string to the URL
      const url = `${process.env.VUE_APP_PRESETS_URL+"/delete"}?${queryString}`;
      await fetch(url, {
        method: "DELETE",
      });
      //remove preset locally
      commit('REMOVE_PRESET', id);
    } catch (error) {
      console.error('Failed to remove presets:', error);
    }
  },
  addNewCategory({commit}, categoryName){
    let newCategory = {
      category:categoryName,
      list:[]
    }
    commit('ADD_PRESET', newCategory);
  }
};
const getters = {
  presets(state) {
    return state.presets
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
