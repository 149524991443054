<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="row" id="mainRow">
        <div class="col-auto" id="mainLogo">
          <a href="https://planner.lightcon.com/"></a>
        </div>

        <div class="col-auto">
          <div class="row ms-2">
            <div class="ms-2">
              <h5 class="schemeName ps-1">
                {{
                  layoutSettings.name ? layoutSettings.name : "UNSAVED SCHEME"
                }}
              </h5>
            </div>
            <MenuMainVue />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-auto">
        <div class="row">
          <div
            class="btn-toolbar"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div class="btn-group me-2">
              <button
                id="newLayoutBtn"
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                title="New layout, remove everything from layout"
                @click="clearAll()"
              >
                <i class="bi bi-file-earmark"></i>
                <span>new</span>
              </button>

              <LayoutSettings ref="settings" />

              <PdfOutput
                v-if="dataIsLoaded"
                :layout="layout"
                @save:layout="save"
              />
            </div>
            <SchemesLoader
              v-if="dataIsLoaded"
              :services="services"
              :layout="layout"
              ref="schemesLoader"
              @update:layoutSizes="resizeCanvasBy"
              @clear:canvas="clearCanvas"
              @update:layoutName="setLayoutName"
              @set:layoutMetadata="setLayoutMetadata"
            />

            <!-- terminals -->
            <div class="btn-group me-2" role="group" aria-label="Terminals">
              <!-- Main Button -->
              <button
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                title="Show/hide item terminals"
                @click="services.terminalServices.hideShowTerminals(layout)"
                :style="{
                  color: services.terminalServices.isTerminalsVisible
                    ? 'red'
                    : '',
                }"
              >
                <i class="bi bi-record-circle"></i>
                <span>terminals</span>
              </button>

              <!-- Vertical Group for Smaller Buttons -->
              <div class="btn-group-vertical">
                <button
                  class="btn btn-outline-dark btn-sm"
                  title="Increase terminal size"
                  @click="services.terminalServices.resizeTerminals(5, layout)"
                >
                  <i class="bi bi-plus"></i>
                </button>
                <button
                  class="btn btn-outline-dark btn-sm"
                  title="Decrease terminal size"
                  @click="services.terminalServices.resizeTerminals(-5, layout)"
                >
                  <i class="bi bi-dash"></i>
                </button>
              </div>
            </div>

            <DecorationsView
              v-if="dataIsLoaded"
              :services="this.services"
              :layout="layout"
            />

            <div class="btn-group me-2" role="group" aria-label="Hider">
              <!--TODO: move table show/hide to menu -->
              <!-- <button
                  class="btn btn-outline-dark d-flex flex-column align-items-center"
                  @click="services.layoutServices.hideObj('', layout)"
                >
                  <i class="bi bi-eye-slash"></i>
                  <span>layout</span>
                </button> -->

              <button
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                type="button"
                title="Undo"
                @click="services.history.undo()"
              >
                <i class="bi bi-arrow-left-circle"></i>
                <span>undo</span>
              </button>
              <button
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                type="button"
                title="Redo"
                @click="services.history.redo()"
              >
                <i class="bi bi-arrow-right-circle"></i>
                <span>redo</span>
              </button>
            </div>

            <div class="btn-group me-2" role="group" aria-label="Cleaner">
              <button
                :disabled="!canvasElementSelected"
                :class="{ 'disabled-button': !canvasElementSelected }"
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                title="Rotate object clockwise"
                @click="services.layoutServices.rotate(90, layout)"
              >
                <i class="bi bi-arrow-clockwise"></i>
                <span>clockwise</span>
              </button>

              <button
                :disabled="!canvasElementSelected"
                :class="{ 'disabled-button': !canvasElementSelected }"
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                title="Rotate object counter-clockwise"
                @click="services.layoutServices.rotate(-90, layout)"
              >
                <i class="bi bi-arrow-counterclockwise"></i>
                <span>counter</span>
              </button>
              <!-- <button
                  class="btn btn-outline-dark d-flex flex-column align-items-center"
                  title="Clean layout"
                  @click="clearCanvas"
                >
                  <i class="bi bi-trash3"></i>
                  <span>clear</span>
                </button> -->

              <button
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                :class="{ flashMe: freezed }"
                title="Enable objects selection"
                @click="enableSelection()"
              >
                <i class="bi bi-sun-fill"></i>
                free
              </button>
              <button
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                title="Disable objects selection"
                @click="disableSelection()"
              >
                <i class="bi bi-cloud-sleet-fill"></i>
                <span>freeze all</span>
              </button>
              <!-- <button
                  class="btn btn-outline-dark d-flex flex-column align-items-center"
                  title="Log canvas elements"
                  @click="getActive"
                >
                  <i class="bi bi-body-text"></i>
                  <span>log</span>
                </button> -->
              <button
                class="btn d-flex flex-column align-items-center"
                :class="[
                  'btn',
                  this.mag ? 'btn-outline-danger' : 'btn-outline-dark',
                ]"
                title="Zoom, click and scroll, move with right button"
                @click="restoreZoom"
              >
                <i class="bi bi-search"></i>
                <span v-if="mag">out</span>
                <span v-else>zoom</span>
              </button>
              <button
                class="btn btn-outline-dark d-flex flex-column align-items-center"
                title="Full screen"
                @click="fullScreen()"
              >
                <i v-if="fScreen" class="bi bi-fullscreen"></i>
                <i v-else class="bi bi-fullscreen-exit"></i>
                <span v-if="fScreen">full</span>
                <span v-else>restore</span>
              </button>
              <SelectionSettingsVue
                v-if="dataIsLoaded"
                :layout="layout"
                :services="services"
                ref="selectionSettings"
                @update:blackBox="resizeBlackBox"
              />
            </div>
            <ProductSelector
              v-if="dataIsLoaded"
              ref="productSelector"
              :selectedProduct="selectedProduct"
              :layout="layout"
            />
            <!--                                        -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="alert alert-warning alert-dismissible fade show"
      style="text-align: left"
      role="alert"
    >
      <strong>Welcome to renewed Optical Layout Planner!</strong>
      Explore our updated planner for a better experience. Prefer the old
      version? It is still available!
      <a href="https://toolbox.lightcon.com/planner"
        >Visit the Old Optical Layout Planner</a
      >
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div
    id="alertPlaceholder"
      class="alert alert-success alert-dismissible fade show d-none"
      style="text-align: left"
      role="alert"
    >
    Scheme <strong id="alertText"></strong> is now saved. You can access it by following this link:
      <a href="https://toolbox.lightcon.com/planner"
        id="alertHref"></a
      >
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>

  <div  class="alert alert-success d-none" role="alert">
      <span id="alertText">Nice, you triggered this alert message!</span>
      <button type="button" class="btn-close" @click="hideAlert()"></button>
    </div>
    <div class="row">
      <!-- layout -->
      <div class="" id="planer-container" ref="planerContainer">
        <div class="col-auto">
          <div
            class="table d-flex justify-content-center"
            ref="table"
            id="table"
          >
            <canvas id="canvas" ref="canvas"></canvas>
          </div>
        </div>
      </div>

      <!-- info -->
      <div class="" id="pinfo">
        <ProductInfo v-if="selectedProduct?.name" :product="selectedProduct" />
      </div>
    </div>
  </div>
  <PresetView></PresetView>
  <AdminLogin></AdminLogin>
  <PresetSaveView :presetData="{}"></PresetSaveView>
</template>
<script>
import { fabric } from "fabric";
import LayoutServices from "@/components/Planner/Services/LayoutServices.js";
import { LineServices } from "@/components/Planner/Services/LineServices";
import { MeasureServices } from "@/components/Planner/Services/MeasureServices";
import { TerminalServices } from "@/components/Planner/Services/TerminalServices";
import { ProductServices } from "@/components/Planner/Services/ProductServices";
import { EntityServices } from "@/components/Planner/Services/EntityServices";
import ProductInfo from "./ProductInfo.vue";
import LayoutHistory from "@/components/Planner/Services/LayoutHistory";
import ProductSelector from "@/components/Planner/views/ProductSelector.vue";
import "@sweetalert2/themes/bootstrap-4";
import SchemesLoader from "@/components/Planner/Schemes/SchemeLoader.vue";
import LayoutSettings from "@/components/Planner/LayoutSettings/LayoutSettings.vue";
import PdfOutput from "./PdfOutput.vue";
import { mapGetters } from "vuex";
import MagnificationServices from "@/components/Planner/Services/MagnificationServices";
import DecorationsView from "../Decorations/DecorationsView.vue";
import SelectionSettingsVue from "../SelectionSettings/SelectionSettings.vue";
import PresetView from "../Preset/PresetLoader.vue";
import Swal from "sweetalert2/";
import MenuMainVue from "../Menu/MenuMain.vue";
import { EventBus } from "@/eventBus";
import AdminLogin from "../Admin/AdminLoginView.vue";
import PresetSaveView from "../Preset/PresetSaver.vue";

export default {
  name: "LayoutPlanner",
  components: {
    MenuMainVue,
    ProductInfo,
    ProductSelector,
    AdminLogin,
    SchemesLoader,
    LayoutSettings,
    PdfOutput,
    DecorationsView,
    SelectionSettingsVue,
    PresetView,
    PresetSaveView

  },
  data() {
    return {
      showTools: false,
      fScreen: true,
      mag: false,
      backup: {},
      dataIsLoaded: false,
      ////////if You add there any init of canvas it produse proxy canvas, it block scalling for all objects//////////////////
      //layout: null,
      schemeInput: "",
      schemeId: "",

      tableContainer: {
        width: 0,
        height: 0,
      },
      exportedJson: "",
      saved: false,
      services: {},
    };
  },

  computed: {
    selected() {
      return this.$store.getters.selection;
    },
    canvasElementSelected() {
      return this.selected instanceof fabric.Object;
    },
    viewerContainerRef() {
      return this.$refs.viewerContainer;
    },
    ...mapGetters(["widthLabel", "heightLabel"]),
    layoutSettings() {
      return this.$store.state.layoutSettings;
    },
    selectedProduct: {
      get() {
        return this.$store.getters.selectedProduct;
      },
      set(value) {
        this.$store.dispatch("updateSelectedProduct", value);
        this.$refs.selectionSettings.updateSizes();
        // this.$refs.selectionSettings.showTools = value !== null;
      },
    },
    freezed() {
      return this.$store.getters.freezed;
    },
  },

  methods: {
    async save(calback) {
      console.debug("Planner, saving ...");
      await this.$refs.schemesLoader.save();
      calback();
    },
    enableSelection() {
      this.$store.commit("SET_FREEZED", false);
      this.services.layoutServices.enableSelection(this.layout);
    },
    disableSelection() {
      this.$store.commit("SET_FREEZED", true);
      this.services.layoutServices.disableSelection(this.layout);
    },
    countLines() {
      var rez = this.layout.getObjects("line").reduce((acc, line) => {
        const color = line.stroke || "unknown";
        const length = Math.sqrt(
          Math.pow(line.width, 2) + Math.pow(line.height, 2)
        );

        if (!acc[color]) {
          acc[color] = 0;
        }
        acc[color] += length;

        return acc;
      }, {});

      for (let [color, length] of Object.entries(rez)) {
        console.info("%cLine Length(mm): " + length, "color: " + color);
      }
      let htmlContent = "";

      for (let [color, length] of Object.entries(rez)) {
        htmlContent += `<p style="color: ${color};">${Math.round(length)}</p>`;
      }

      Swal.fire({
        title: "Line length (mm)",
        html: htmlContent ? htmlContent : "No lines detected",
        icon: "info",
      });
    },
    fullScreen() {
      this.fScreen = !this.fScreen;
      var container = document.getElementById("planer-container");
      if (this.fScreen) {
        container.style.width = "70%";
      } else {
        container.style.width = "99%";
      }
      this.tableContainer.width =
        document.getElementById("planer-container").clientWidth - 50;
      this.tableContainer.height =
        document.getElementById("planer-container").clientHeight - 50;
      this.resizeCanvas();
    },
    restoreZoom() {
      if (this.mag) {
        this.layout.viewportTransform = this.backup.viewportTransform;
        this.services.magnificationServices.disableMagnificationListeners();
        this.resizeCanvas();
      } else {
        this.services.magnificationServices.enableMagnificationListeners();
      }
      this.mag = !this.mag;
    },
    clearAll() {
      this.layout.name = "";
      this.layoutSettings.layoutMetadata = {};
      this.layoutSettings.canvasOffset = 0;
      this.clearCanvas();
    },

    setLayoutMetadata(metadata) {
      this.layoutSettings.layoutMetaData = metadata;
    },
    updateLayoutSettings(settings) {
      console.debug("updating layout settings");
      this.layoutSettings = settings;
      this.resizeCanvas();
      this.layout.requestRenderAll();
    },
    setLayoutName(name) {
      console.debug("setting layout: ", name);
      this.layout.name = name;
    },
    resizeCanvasBy(parameters) {
      console.debug("resizing canvas by dimensions: ", parameters);
      this.layoutSettings.canvasOffset = parameters.canvasOffset || 0;
      this.layoutSettings.gridSize = parameters.offsetX || 0;
      this.layoutSettings.width = parameters.x;
      this.layoutSettings.height = parameters.y;
    },
    defineCanvasBoxSizes() {
      this.tableContainer.width =
        document.getElementById("planer-container").clientWidth - 50;
      this.tableContainer.height =
        document.getElementById("planer-container").clientHeight - 50;
      window.addEventListener("resize", () => {
        this.tableContainer.width =
          document.getElementById("planer-container").clientWidth - 50;
        this.tableContainer.height =
          document.getElementById("planer-container").clientHeight - 50;
        this.resizeCanvas();
      });
    },
    updateSelectedProduct(product) {
      this.selectedProduct = product;
    },
    toggleUnits() {
      this.layoutSettings.imperialUnits = !this.layoutSettings.imperialUnits;
    },

    getActive() {
      console.info("layout: ", this.layout);
    },
    clearCanvas() {
      this.layout.clear();
      this.services.layoutServices.addGrid(
        this.layout,
        this.layoutSettings,
        this.widthLabel,
        this.heightLabel
      );
      console.info("%ccanvas cleaned", "color: darkseagreen;");
    },
    removeGrid() {
      console.debug("removing grid");
      var grid = this.layout.getObjects().find((obj) => obj.name === "grid");
      this.layout.remove(grid);
    },
    createCanvas() {
      console.debug("creating canvas");
      this.layout = new fabric.Canvas(this.$refs.canvas, {});
    },
    setupCanvas() {
      console.debug("setting up canvas");
      this.layoutSettings.zoom = this.services.layoutServices.defineZoom(
        this.tableContainer.width,
        this.tableContainer.height,
        this.layoutSettings
      );

      this.$refs.canvas.width =
        this.layoutSettings.width * this.layoutSettings.zoom +
        this.layoutSettings.canvasLablesFontSize + this.layoutSettings.canvasOffset;
      this.$refs.canvas.height =
        this.layoutSettings.height * this.layoutSettings.zoom +
        this.layoutSettings.canvasLablesFontSize + this.layoutSettings.canvasOffset;
      this.createCanvas();
      this.layout.setZoom(this.layoutSettings.zoom);
      this.layout.requestRenderAll();
    },
    resizeCanvas() {
      console.debug("resizing canvas");
      this.layoutSettings.zoom = this.services.layoutServices.defineZoom(
        this.tableContainer.width,
        this.tableContainer.height,
        this.layoutSettings
      );
      this.layout.setDimensions({
        width:
          this.layoutSettings.width * this.layoutSettings.zoom +
          this.layoutSettings.gridSize + this.layoutSettings.canvasLablesFontSize + this.layoutSettings.canvasOffset,
        height:
          this.layoutSettings.height * this.layoutSettings.zoom +
          this.layoutSettings.gridSize + this.layoutSettings.canvasLablesFontSize + this.layoutSettings.canvasOffset,
      });
      this.layout.setZoom(this.layoutSettings.zoom);
      this.removeGrid(this.layout);
      this.services.layoutServices.addGrid(
        this.layout,
        this.layoutSettings,
        this.widthLabel,
        this.heightLabel
      );
    },
    enableServices() {
      console.debug("enabling layout services");
      this.services.layoutServices = new LayoutServices();
      this.services.lineServices = new LineServices(
        this.layoutSettings,
        this.layout
      );
      this.services.measuringServices = new MeasureServices(
        this.layoutSettings,
        this.layout,
        this.services.layoutServices
      );
      this.services.terminalServices = new TerminalServices(this.layout);
      this.services.productServices = new ProductServices(
        this.layoutSettings,
        this.services.terminalServices
      );
      this.services.entityServices = new EntityServices();
      this.services.history = new LayoutHistory(
        this.layout,
        this.services.layoutServices,
        this.layoutSettings
      );
      this.services.magnificationServices = new MagnificationServices(
        this.layout
      );
    },
    contextListener(e) {
      e.preventDefault();
    },
    scaling(event) {
      if (event.target.name == "BLACK-BOX") {
        this.$refs.selectionSettings.updateSizes(
          event.target.getObjectScaling()
        );
      }
    },

    starter() {
      try {
        this.defineCanvasBoxSizes();
        this.services.layoutServices = new LayoutServices();

        this.setupCanvas();
        this.enableServices();
        this.services.layoutServices.addGrid(
          this.layout,
          this.layoutSettings,
          this.widthLabel,
          this.heightLabel
        );
        this.services.layoutServices.enableLayoutListeners(
          this.layout,
          this.services.productServices,
          this.layoutSettings
        );
        this.layout.on("selection:created", () => {
          this.selectedProduct = this.layout.getActiveObject();
          this.setControlsVisibilityForGroupSelection();
        });
        this.layout.on("selection:updated", () => {
          this.selectedProduct = this.layout.getActiveObject();
          this.setControlsVisibilityForGroupSelection();
        });

        this.layout.on("selection:cleared", () => {
          this.selectedProduct = null;
        });
        this.layout.on("object:scaling", this.scaling);

        this.services.history.enableListeners(this.layout);
        console.info("%cPlaner ready to work", "color: darkseagreen;");
      } catch (error) {
        console.error(error);
      } finally {
        this.dataIsLoaded = true;
        this.backup.vptCoords = this.layout.vptCoords;
        this.backup.viewportTransform = this.layout.viewportTransform;
      }
    },
    setControlsVisibilityForGroupSelection() {
      var activeObject = this.layout.getActiveObject();
      if (
        !(
          (activeObject?.name &&
            /^(tube|black-box)/i.test(activeObject.name)) ||
          activeObject?.type === "i-text"
        )
      ) {
        activeObject.set({ hasControls: false });
        this.layout.requestRenderAll();
        console.debug("Controls disabled for the selected object");
      }
    },
    paintSelectionBorders() {
      this.layout.forEachObject((obj) => {
        if (
          Object.prototype.hasOwnProperty.call(obj, "borderColor") ||
          obj?.isProduct
        ) {
          obj.borderColor = this.layoutSettings.selectionColor;
        }
      });
    },
    resizeBlackBox(blackBox) {
      var activeObject = this.layout.getActiveObject();
      if (activeObject != null) {
        activeObject
          .set("scaleX", blackBox.width / activeObject.width)
          .set("scaleY", blackBox.height / activeObject.height)
          .setCoords();
        this.layout.requestRenderAll();
      }
    },
  },
  watch: {
    "layoutSettings.zoom": function () {
      var self = this;
      var catchedRullers = this.layout.getObjects().filter((obj) => {
        return obj?.name === "ruler";
      });
      if (catchedRullers.length > 0) {
        catchedRullers.forEach((ruler) => {
          ruler.getObjects()[3].fontSize = Math.floor(
            23 / self.layoutSettings.zoom
          );
          self.layout.requestRenderAll();
        });
      }
      this.layout.requestRenderAll();
    },
    "layoutSettings.width": "resizeCanvas",
    "layoutSettings.selectionColor": "paintSelectionBorders",
    "layoutSettings.height": "resizeCanvas",
    "layoutSettings.imperialUnits": function (newVal) {
      let selectedText = newVal
        ? "imperial units selected"
        : "metric units selected";
      console.debug("%c" + selectedText, "color: goldenrod;");
      if (newVal) {
        this.layoutSettings.gridSize = 25.4;
        //setting up canvas to whole numbers
        var desiredWidth = Math.ceil(
          this.layoutSettings.width / this.layoutSettings.mmToFt
        );
        var desiredheight = Math.ceil(
          this.layoutSettings.height / this.layoutSettings.mmToFt
        );
        this.layoutSettings.width = desiredWidth * this.layoutSettings.mmToFt;
        this.layoutSettings.height = desiredheight * this.layoutSettings.mmToFt;
        this.resizeCanvas();
      } else {
        this.layoutSettings.gridSize = 25;
        this.layoutSettings.width = Math.round(this.layoutSettings.width);
        this.layoutSettings.height = Math.round(this.layoutSettings.height);
        this.resizeCanvas();
      }
      this.layoutSettings.gridOffset = this.layoutSettings.gridSize;
    },
  },
  mounted() {
    EventBus.on("newLayout", this.clearAll);
    EventBus.on("countLines", this.countLines);
    EventBus.on("switchUnits", this.toggleUnits);
    EventBus.on("export to PNG", () => {
      this.services.layoutServices.downloadAsPng(this.layout);
    });
    EventBus.on("undo", () => {
      this.services.history.undo();
    });
    EventBus.on("redo", () => {
      this.services.history.redo();
    });
    EventBus.on("toggleTerminals", () => {
      this.services.terminalServices.hideShowTerminals(this.layout);
    });
    EventBus.on("free", () => {
      this.enableSelection();
    });
    EventBus.on("debug", () => {
      this.getActive();
    });
    EventBus.on("hideShowlayout", () => {
      this.services.layoutServices.hideObj("", this.layout);
    });
    this.starter();
    document.getElementById("mainLogo").addEventListener("click", function () {
      Swal.fire({
        title: "Warning: You are about to leave this page.",
        text: "Any unsaved changes will be lost if you proceed. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = process.env.VUE_APP_MOTHER_URL;
        }
      });
    });
  },
  beforeMount() {
    //only for terminal indication
    this.services.terminalServices = new TerminalServices();
  },
  beforeUnmount() {
    //avoid too many listener on updating
    this.services.layoutServices.disableLayoutListeners(
      this.layout,
      this.services.productServices
    );
    EventBus.off("newLayout", this.clearAll);
    EventBus.off("countLines", this.countLines);
    EventBus.off("switchUnits", this.toggleUnits);
    EventBus.off("export to PNG", () => {
      this.services.layoutServices.downloadAsPng(this.layout);
    });
    EventBus.off("undo", () => {
      this.services.history.undo();
    });
    EventBus.off("redo", () => {
      this.services.history.redo();
    });
    EventBus.off("toggleTerminals", () => {
      this.services.terminalServices.hideShowTerminals(this.layout);
    });
    EventBus.off("free", () => {
      this.enableSelection();
    });
    EventBus.off("debug", () => {
      this.getActive();
    });
  },
  created() {},
};
</script>
<style>
#mainLogo {
  background-image: url("../../../assets/img.svg");
  background-position: right center; /* horizontal vertical */
  background-size: contain; /* or contain, depending on what you need */
  background-repeat: no-repeat;
  margin-top: 5px;
  height: 68px;
  width: 171px;
  cursor: pointer;
}

#mainRow {
  background-color: #000e44;
}

#mainTitle {
  background-image: url("../../../assets/img.png");
  background-position: right center; /* horizontal vertical */
  background-size: contain; /* or contain, depending on what you need */
  background-repeat: no-repeat;
  width: 270px;
}

#planer-container {
  width: 70%;
  min-height: 80vh;
}

#pinfo {
  width: 30%;
}

.table {
  width: 100%;
}

.custom-dropdown {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: 100%;
}

.hidden-checkbox {
  display: none;
}

span {
  cursor: pointer;
}
.flashMe {
  animation: flashing 0.7s infinite;
}
.schemeName {
  color: whitesmoke;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  text-align: left;
}
.btn {
  border: none !important;
  border-radius: 0px !important;
}
.btn-group {
  border: 1px solid black !important;
  border-radius: 5px !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

@keyframes flashing {
  0%,
  100% {
    color: rgb(5, 5, 5);
    box-shadow: 5px 10px 10px rgba(255, 0, 0, 0.5);
  }
  50% {
    color: rgb(255, 0, 0);
    box-shadow: 5px 1px 1px rgb(254, 254, 255);
  }
}
</style>