<template>
  <div
    class="modal fade"
    id="presetDetailsModal"
    tabindex="-1"
    aria-labelledby="presetDetailsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="presetDetailsModalLabel">
            Edit Preset
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label
                :class="{ 'is-invalid': isWrongTitle }"
                for="preset-title"
                class="col-form-label"
                >Title:</label
              >
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isWrongTitle }"
                v-model="localPresetData.title"
                id="preset-title"
              />
            </div>

            <div class="mb-3">
              <label
                for="preset-category"
                class="col-form-label"
                :class="{ 'is-invalid': isWrongCategory }"
                >Category:</label
              >
              <div class="row g-0">
                <div class="col">
                  <select
                    class="form-select"
                    v-model="localPresetData.category"
                    :class="{ 'is-invalid': isWrongCategory }"
                    id="preset-category"
                  >
                    <option
                      v-for="category in categories"
                      :key="category"
                      :value="category"
                    >
                      {{ category }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button
                    @click="isNewCategory = !isNewCategory"
                    type="button"
                    class="btn btn-secondary h-100"
                  >
                    <i v-if="isNewCategory" class="bi bi-file-minus"></i>
                    <i v-else class="bi bi-plus-square"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row g-0" v-if="isNewCategory">
              <div class="col">
                <input
                  type="text"
                  v-model="newCategoryInput"
                  class="form-control"
                  placeholder="Enter new category"
                  :class="{ 'is-invalid': isNewCategoryInputFailed }"
                />
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  class="btn btn-secondary h-100"
                  @click="addNewCategoryM()"
                >
                  Add
                </button>
              </div>
            </div>

            <div class="mb-3">
              <label for="preset-comments" class="col-form-label"
                >Comments:</label
              >
              <input
                type="text"
                class="form-control"
                v-model="localPresetData.comments"
                id="preset-comments"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="savePreset">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { EventBus } from "@/eventBus";
import bootstrapServices from "@/components/Planner/Services/botstrapServices";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    presetData: {
      type: Object,
      required: true,
    },
    callbackFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      localPresetData: { ...this.presetData },
      isWrongCategory: false,
      isWrongTitle: false,
      isNewCategory: false,
      newCategoryInput: "",
      isNewCategoryInputFailed: false,
      isWrongNewCategory:false
    };
  },
  created() {
    this.fetchPresets();
  },
  computed: {
    ...mapGetters(["presets"]),
    categories() {
      return this.presets.map((item) => item.category);
    },
    layoutSettings() {
      return this.$store.state.layoutSettings;
    },
  },
  methods: {
    ...mapActions(["fetchPresets", "addNewCategory"]),
    addNewCategoryM() {
      if (!this.newCategoryInput) {
        this.isNewCategoryInputFailed = true;
        return;
        
      } 
      if(this.categories.some(category=>category===this.newCategoryInput)){
        this.isNewCategoryInputFailed = true;
        return;
      }
      this.addNewCategory(this.newCategoryInput);
      this.isNewCategoryInputFailed = false;
    },
    savePreset() {
      if (!this.isFielsdValid()) return;

      EventBus.emit("savePreset", this.localPresetData);
    },

    openPresetDetailsModal() {
      bootstrapServices.methods.openModal("presetDetailsModal");
    },
    isFielsdValid() {
      if (!this.localPresetData.category) {
        this.isWrongCategory = true;
        return false;
      }
      this.isWrongCategory = false;
      if (!this.localPresetData.title) {
        this.isWrongTitle = true;
        return;
      }
      this.isWrongTitle = false;
      return true;
    },
  },
  async mounted() {
    EventBus.on("saveAsPreset", () => {
      this.openPresetDetailsModal();
    });
  },
  watch: {
    presetData: {
      handler(newData) {
        this.localPresetData = { ...newData };
      },
      deep: true,
    },
  },
};
</script>

<style>
.is-invalid {
  border-color: #dc3545;
}
</style>