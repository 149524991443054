<template>
  <div class="btn-group me-2" role="group" aria-label="Schemes">
    <button
      id="saveSchemebutton"
      class="btn btn-outline-dark d-flex flex-column align-items-center"
      title="Save layout"
      @click="save()"
    >
      <i class="bi bi-floppy"></i>
      <span>save</span>
    </button>
    <input
      id="schemeNameInput"
      class="form-control border-dark"
      type="text"
      v-model="this.schemeInput"
      placeholder="Scheme id"
    />
    <button
      id="loadSchemeBtn"
      class="btn btn-outline-dark d-flex flex-column align-items-center"
      type="button"
      title="Add scheme to existing layout"
      @click="setScheme(schemeInput)"
    >
      <i class="bi bi-journal-arrow-down"></i>
      <span>load</span>
    </button>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus";
import { Scheme } from "@/components/Planner/Entities/Scheme";
import SchemesServices from "@/components/Planner/Schemes/SchemesServices";
import Swal from "sweetalert2/";
import "@sweetalert2/themes/bootstrap-4";
import axios from "axios";
import { propertiesExtender } from "@/components/helpers/Helpers";
import { mapActions } from "vuex";
export default {
  props: {
    services: {
      type: Object,
      default: () => ({}),
      validator: (value) => {
        if (!value) {
          console.error("serices not defined");
          return false;
        }
        return true;
      },
    },
    layout: {
      type: Object,
      default: () => ({}),
      validator: (value) => {
        if (!value) {
          console.error("layout not defined");
          return false;
        }
        return true;
      },
    },
  },
  name: "SchemeLoader",
  data() {
    return {
      schemeInput: "",
      schemeId: "",
      applyNewSizes: false,
      metadata: {},
      tableParameters:{}
    };
  },
  computed: {
    productList() {
      return this.$store.getters.categorizedProducts;
    },
    layoutSettings() {
      return this.$store.state.layoutSettings;
    },
  },
  methods: {
    ...mapActions(["fetchPresets"]),
    showAlert(message) {
      const alertPlaceholder = document.getElementById('alertPlaceholder');
      const alertText = document.getElementById('alertText');
      alertText.textContent = message; 
      const alertHref = document.getElementById('alertHref');
      alertHref.href = "scheme/"+message; 
      alertHref.textContent = "https://planner.lightcon.com/scheme/" + message; 
      alertPlaceholder.classList.remove('d-none'); 
    },
    async saveToBlob() {
      var canvasData = this.layout.toDataURL("image/png");
      var formData = new FormData();

      formData.append("file", canvasData);
      var url =
        process.env.VUE_APP_API_BASE_URL + "images/scheme/" + this.schemeId;
      await fetch(url, {
        method: "POST",
        body: formData,
      }).catch((error) => console.error("Klaida:", error));
    },
    createOutputFromCanvas(newScheme){
      var output = new Scheme();
        output.canvas = this.services.entityServices.getCanvasDto(this.layout);
        output.grid = this.layoutSettings.gridSize;
        output.gridSpacing = this.layoutSettings.gridSize;
        if (!newScheme) {
          output.name = this.layout.name || "";
        }
        output.products = this.services.productServices.getProductList(
          this.layout
        );
        output.layoutSettings = this.layoutSettings.layoutMetaData;
        output.tableParameters = {
          x: this.layoutSettings.width,
          y: this.layoutSettings.height,
          offsetX: this.layoutSettings.gridSize,
          offsetY: this.layoutSettings.gridSize,
          type: this.layoutSettings.imperialUnits ? "imperial" : "metric",
          canvasOffset: this.layoutSettings.canvasOffset
        };
        return output;
    },
    async saveasPreset(data){
      //enapble spinner
      this.$store.commit("SET_ISLOADING", true);
      console.log('preset data: ', data);

      //packing canvas 
      var prepairedCanvas = this.createOutputFromCanvas(true);
      console.log('output: ', prepairedCanvas);
      
      let output = {
        Id:"1",
        ...data,
        Scheme: prepairedCanvas,
        Image:this.layout.toDataURL({ format: 'png', quality: 1.0 })
      }
      console.log(output);
          const response = await fetch(process.env.VUE_APP_PRESET_SAVE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(output),
        });

        const data1 = await response.json();
        console.debug("Success:", data1);

          
      this.$store.commit("SET_ISLOADING", false);
    },
    async save(as) {
      try {
        this.$store.commit("SET_ISLOADING", true);
        
        var output = this.createOutputFromCanvas(as);

        const url =
          process.env.VUE_APP_API_BASE_URL +
          process.env.VUE_APP_API_POST_SCHEME;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(output),
        });

        const data = await response.json();
        console.debug("Success:", data);

        const name = data.name;
        this.showAlert(name);
        if (!as) {
          this.$emit("update:layoutName", name);
          this.schemeId = name; // unnecessary loading activation
        }
        this.schemeInput = name;

        // Animate and save operations
        this.animatePositive(document.getElementById("schemeNameInput"));
        this.animatePositive(document.getElementById("saveSchemebutton"));
        await this.saveToBlob().then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `saved`,
            showConfirmButton: false,
            timer: 1500,
          });
        });
      } catch (error) {
        this.animateNegative(document.getElementById("schemeNameInput"));
        this.animateNegative(document.getElementById("saveSchemebutton"));
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      } finally {
        this.$store.commit("SET_ISLOADING", false);
      }
    },
    async extractObjects(savedscheme, applyNewSizes) {
      const scheme = SchemesServices.unifyPropertiesNames(
        JSON.parse(savedscheme)
      );
      this.metadata = scheme.layoutSettings;
      console.debug("metadata: ", this.metadata);

      // this.$emit("update:layoutName", schemeName);

      if (applyNewSizes) {
        console.debug("applying new sizes: ", scheme.tableParameters.x);
        this.tableParameters = scheme.tableParameters;
        this.$emit("update:layoutSizes", scheme.tableParameters);
        this.layoutSettings.imperialUnits =
          scheme.tableParameters.type === "imperial";
        this.applyNewSizes = false;
      }
      var allObjects = await SchemesServices.extractor(
        scheme,
        this.services.layoutServices,
        this.layoutSettings
      );
      propertiesExtender(allObjects);

      let response;
      if (!SchemesServices.isAdapterNeeded(scheme)) {
        
        response = allObjects;
      } else {
        const SCALEFACTOR = scheme.gridSpacing / scheme.grid;

        var adoptedObjects = await SchemesServices.adoptOldScheme(
          allObjects,
          SCALEFACTOR,
          this.services.terminalSize,
          this.services.terminalServices,
          this.services.productServices,
          this.productList
        );

        response = adoptedObjects;
      }
      this.layout.forEachObject((obj) =>
        console.debug(
          "loaded objets: ",
          obj?.name?.padEnd(20) || "undefined".padEnd(20),
          obj?.type?.padEnd(7) || "undefined".padEnd(7),
          obj
        )
      );

      console.debug("total loaded: ", this.layout.getObjects(), " objects");
      return response;
    },
    async addScheme(schemeName, fromPreset = false) {
      console.info("adding scheme");
      try {
        this.$store.commit("SET_ISLOADING", true);
        if (!schemeName) {
          this.animateNegative(document.getElementById("schemeNameInput"));
          return;
        }
       
        var savedscheme = await SchemesServices.getScheme(schemeName, fromPreset);
                
        var objects = await this.extractObjects(savedscheme, this.applyNewSizes);
        this.$store.commit("PAUSE_TRACKING");
        objects.forEach((obj) => this.layout.add(obj));
        this.$store.commit("RESUME_TRACKING");
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Scheme ${schemeName} added`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.animatePositive(document.getElementById("schemeNameInput"));
      } catch (error) {
        console.error("cannot load it: ", error);
        this.animateNegative(document.getElementById("schemeNameInput"));
      } finally {
        this.$store.commit("SET_ISLOADING", false);
      }
    },
    setScheme(inp) {
      console.info("setting scheme");
      if (this.schemeId != inp) {
        this.$emit("clear:canvas");
        Swal.fire({
          title: "Apply scheme sizes??",
          showDenyButton: true,
          confirmButtonText: "Apply",
          denyButtonText: `Keep current`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.applyNewSizes = true;
          }
          this.schemeId = inp;
          this.$emit("update:layoutName", inp);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Scheme ${inp} has already been uploaded earlier`,
        });

        console.warn("Scheme ", inp, " has already been uploaded earlier");
      }
    },
    async readParamsFromAdress() {
      const path = window.location.pathname;
      const segments = path.split("/");

      var Id = segments[segments.length - 1];
      var module = segments[segments.length - 2];

      if (module === "scheme" && Id) {
        this.applyNewSizes = true;
        this.schemeId = Id;
      }
      if (module === "png") {
        if (!Id) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `You forgot enter scheme name: .../png/Id`,
          });
          return;
        }

        var pngLocation = process.env.VUE_APP_PNG_URL + Id + ".png";
        var pngResponse;
        try {
          pngResponse = await axios.head(pngLocation);
          console.log("pngResponse", pngResponse);
        } catch (error) {
          console.warn(error);
          switch (error.response.status) {
            case 404:
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `Document ${Id} do not exist`,
              });
              return;
            default:
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `${error.message}`,
              });
              return;
          }
        }

        console.log(process.env.VUE_APP_PNG_URL + Id + ".png");

        window.location.href = process.env.VUE_APP_PNG_URL + Id + ".png";
      }
      console.debug(module, ":", Id);
    },
    animatePositive(element) {
      element.style.animation = "none";

      setTimeout(() => {
        element.style.animation = "positiveAnimation 2s";
      }, 50);
    },
    animateNegative(element) {
      element.style.animation = "none";

      setTimeout(() => {
        element.style.animation = "negativeAnimation 2s";
      }, 50);
    },
  },
  watch: {
    async schemeId() {
      if (this.schemeId) {
        this.schemeInput = this.schemeId;
        this.$emit("clear:canvas");
        await this.addScheme(this.schemeId);
        this.$emit("set:layoutMetadata", this.metadata);
        this.$emit("update:layoutName", this.schemeId);
        this.$store.commit("SET_SCHEME_NAME", this.schemeId);
      }
    },
  },
  async mounted() {
    console.log('Scheme loader mounted...');
    await this.readParamsFromAdress();
    EventBus.on("save", await this.save);
    EventBus.on("saveAs", () => {
      this.save(true);
    });
    EventBus.on("savePreset", async (data) => {
      await this.saveasPreset(data);
      await this.fetchPresets();
    });
    EventBus.on("load", () => {
      this.setScheme(this.schemeInput);
    });
    EventBus.on("load:preset", (schemeUrl) => {
      console.log('loading preset...');
      this.$emit("clear:canvas");
      this.applyNewSizes=true;
      this.addScheme(schemeUrl, true);
    });
    EventBus.on("import", () => {
      this.addScheme(this.schemeInput);
    });
  },
};
</script>

<style scoped>
#schemeNameInput {
  width: 100px;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  background: rgb(240, 240, 240) !important;
}
</style>