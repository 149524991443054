<template>
  
    <div class="dropdown">
      <button
      id="colorSelector"
        class="btn btn-sm btn-outline-dark dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ref="dropdownButton"
        title="Select color to apply to the selected components or all."
        :style="{ backgroundColor: selectedColor }"
      ></button>
      <ul class="dropdown-menu" aria-labelledby="dropdownColorSelector">
        <li v-for="(value, key) in colors" :key="key">
          <div class="d-flex align-items-center mx-2">
            <div
              class="me-2"
              :style="{
                backgroundColor: value,
                height: '30px',
                width: '50px',
                display: 'inline-block',
              }"
              @click="selectColor(value, false)"
            ></div>
            <button
              class="btn btn-outline-dark"
              title="Apply to all"
              @click="selectColor(value, true)"
            >
              <i class="bi bi-check2-all"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  
</template>

<script>
export default {
  props: {
    activeColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      colors: {
        1: "#CC2F1D",
        2: "#003A7E",
        3: "#46A455",
        4: "#EABF3F",
        5: "#000000",
        6: "#FFFFFF",
        7: "#EA8C00",
        8: "#5B2283",
        9: "#0093CD",
        10: "#C7007F",
        11: "#797E83",
        12: "#00A49D",
      },
      selectedColor: this.activeColor,
    };
  },
  methods: {
    selectColor(color, applyToAll) {
      console.debug("color: ", color);
      this.selectedColor = color;
      this.$emit("update:selectedColor", this.selectedColor, applyToAll);
    },
  }
};
</script>

<style scoped>
#colorSelector{
height: 63px;
border-radius: 10% !important;
}
.dropdown-menu {
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 30px 20px 20px #a6a6a6;
  backdrop-filter: blur(3px);
}
</style>