import { createStore } from 'vuex';
import layoutSettings from './layoutSettings';
import products from './products'
import history from './history'
import adminStore from '@/components/Planner/Admin/adminStore';
import presetsStore from '@/components/Planner/Preset/presetsStore';
export default createStore({
  state() {
    return {
      isLoading:false,
    }
  },
  modules:{
    layoutSettings,
    products,
    history,
    adminStore,
    presetsStore
  },
  mutations: {
    SET_ISLOADING(state, condition){
      state.isLoading = condition
    }
  },
  actions: {
  },
  getters: {
    isLoading(state){
      return state.isLoading
    }
  }
});
