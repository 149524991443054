import { fabric } from "fabric";
import store from '@/store/index'
export default class SchemesServices {
    constructor() { }

    static async getScheme(schemeName, fromPreset) {
        if (!schemeName) {
            console.error('undefined name of scheme');
        }
        console.log('getScheme', schemeName, fromPreset);
        // scheme name if form regular store or path if from preset
        var url = fromPreset
            ? process.env.VUE_APP_TOOLBOX_BLOB_URL + schemeName.replace(/^\.\//, '')
            : process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_GET_SCHEME + schemeName.replace(/^\.\//, '');
        const response = await fetch(url)
        const scheme = await response.text();

        return scheme
    }

    static isAdapterNeeded(scheme) {
        if (scheme.gridSpacing / scheme.grid !== 1) {
            console.debug('Adopting scheme ...', scheme);
            return true
        }
        return false
    }
    static async extractor(scheme, layoutServices, layoutSettings) {
        const savedCanvas = scheme.canvas
        const tempCanvas = new fabric.StaticCanvas()
        store.commit('PAUSE_TRACKING')
        await layoutServices.loadFromJson(savedCanvas, tempCanvas, layoutSettings)
        store.commit('RESUME_TRACKING')
        return tempCanvas.getObjects();
    }

    static async adoptOldScheme(objects, SCALEFACTOR, terminalSize, terminalServices, productServices, productList) {

        var allProducts = objects.filter((obj) => obj.name != "terminal");

        //const SCALEFACTOR = scheme.gridSpacing / scheme.grid;

        console.debug(' SCALEFACTOR defined: ', SCALEFACTOR);

        var terminalsInScheme = objects.filter(
            (obj) => obj.name === "terminal"
        );
        console.debug("LoadedProducts: ", allProducts, "loadedTerminals: ", terminalsInScheme);
        var objectsToReturn = [];
        allProducts.forEach((product) => {

            product.scaleX *= SCALEFACTOR;
            product.scaleY *= SCALEFACTOR;
            product.left = product.left * SCALEFACTOR;
            product.top = product.top * SCALEFACTOR;
            product.snapPositionX = product.snapPositionX * SCALEFACTOR;
            product.snapPositionY = product.snapPositionY * SCALEFACTOR;
            product.setCoords();

            var options;
            options = {
                name: product.name === 'other' ? '' : product.name,
                isProduct: product.name !== 'other',
            }
            product.set(options);

            if (!Object.prototype.hasOwnProperty.call(product, 'terminals') || product?.terminals?.length < 1) {

                //layout.add(product);
                objectsToReturn.push(product)
                return;
            }
            var group = new fabric.Group([product]);

            group.set(options);

            var result = [];
            product.terminals.forEach((objectTerminal) => {
                var wantedTerminal = terminalsInScheme.filter(
                    (terminal) => terminal.id === objectTerminal
                );

                result.push({
                    top: wantedTerminal[0].top * SCALEFACTOR,
                    left: wantedTerminal[0].left * SCALEFACTOR,
                });
            });
            group.set("terminals", result);

            if (group.terminals) {
                var terminals =
                    terminalServices.createTerminals(
                        group.terminals,
                        group
                    );
                terminals.forEach((terminal) => {
                    group.addWithUpdate(terminal);
                });

                var deformation =
                    terminalServices.countDeformations(
                        group.terminals,
                        terminalSize,
                        group.width,
                        group.height,
                        { top: group.top, left: group.left }
                    );
                console.debug('deformation of,', product.name, ': ', deformation);
                productServices.formatGroup(group, product, deformation);
                group.set({
                    lockScalingX: product.lockScalingX,
                    lockScalingY: product.lockScalingY,
                    descriptionImage: SchemesServices.updateDescriptionImage(group.name, productList),
                    id: store.getters.idFromName(product?.name)
                })
            }
            objectsToReturn.push(group)
        })
        return objectsToReturn
    }
    static updateDescriptionImage(name, productList) {

        var categories = productList
        var products = [].concat(...categories.map(category => category.list));
        var product = products
            .filter((product) => {
                return product.name === name;
            })
        if (product.length === 1 && product[0].descriptionImage != null) {
            return product[0].descriptionImage
        }
    }
    static updateDescriptionId(name, productList) {

        var categories = productList
        var products = [].concat(...categories.map(category => category.list));
        var product = products
            .filter((product) => {
                return product.name === name;
            })
        if (product.length === 1 && product[0].id != null) {
            return product[0].id
        }
    }
    static updateLongName(name, productList) {

        var categories = productList
        var products = [].concat(...categories.map(category => category.list));
        var product = products
            .filter((product) => {
                return product.name === name;
            })
        if (product.length === 1 && product[0].longName != null) {
            return product[0].longName
        }
    }
    static unifyPropertiesNames(data) {
        function toCamelCase(str) {

            if (str.length === 1 && str.toUpperCase() === str) {
                return str.toLowerCase();
            }

            return str
                .replace(/^[A-Z]/, match => match.toLowerCase())
                .replace(/([-_][a-z])/g, (group) => group.toUpperCase());
        }

        function keysToCamelCase(obj) {
            if (obj === null || obj === undefined || typeof obj !== 'object') return obj;

            if (Array.isArray(obj)) {
                return obj.map((v) => keysToCamelCase(v));
            } else {
                return Object.keys(obj).reduce((accumulator, key) => {
                    accumulator[toCamelCase(key)] = keysToCamelCase(obj[key]);
                    return accumulator;
                }, {});
            }
        }
        const camelCasedData = keysToCamelCase(data);
        return camelCasedData
    }

}